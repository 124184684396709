import config from './configs/config';
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from 'firebase/messaging';

// Firebase 구성 설정
export const firebaseConfig = {
  apiKey: config.fcm.apiKey,
  authDomain: config.fcm.authDomain,
  projectId: config.fcm.projectId,
  storageBucket: config.fcm.storageBucket,
  messagingSenderId: config.fcm.messagingSenderId,
  appId: config.fcm.appId,
  measurementId: config.fcm.measurementId,
};

// Firebase 앱 초기화
export const firebaseAppInstance = initializeApp(firebaseConfig);

// FOREGROUND MESSAGE 처리
onMessage(getMessaging(firebaseAppInstance), (payload) => {
  const notificationTitle = payload.notification.title;
  const notificationOptions = {
    body: payload.notification.body,
    icon: payload.notification.image
  };
  if (Notification.permission === 'granted') {
    new Notification(notificationTitle, notificationOptions);
  }
});

// 서비스 워커 등록 및 관리
if ("serviceWorker" in navigator) {
  (async () => {
    try {
      // 기존 서비스 워커 삭제
      const registrations = await navigator.serviceWorker.getRegistrations();
      registrations.forEach((registration) => {
        console.log('기존 서비스 워커 삭제:', registration.scope);
        registration.unregister(); // 서비스 워커 삭제
      });

      // 새로운 서비스 워커 등록
      const registration = await navigator.serviceWorker.register(
        '/firebase-messaging-sw.js',
        { scope: '/firebase-cloud-messaging-push-scope' }
      );
      console.log('새 서비스 워커 등록 완료:', registration);

      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        installingWorker.onstatechange = async () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              console.log('새 서비스 워커가 설치되었습니다.');
            } else {
              console.log('서비스 워커가 오프라인 콘텐츠를 위해 캐시되었습니다.');
            }
          }
        };
      };
    } catch (error) {
      console.error('Service Worker 등록 실패:', error);
    }
  })();
}


// // 서비스 워커 등록
// navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/firebase-cloud-messaging-push-scope' })
//   .then((registration) => {
//     // 기존에 등록된 서비스 워커가 있더라도 새롭게 업데이트
//     registration.update();  // 이 부분이 강제로 업데이트를 수행

//     // 서비스 워커가 성공적으로 등록되었을 때
//     // console.log('Service Worker 등록 완료:', registration);
//     // serviceWorkerRegistration = registration;

//     // 새 서비스 워커가 있을 때 활성화하고 대기 중인 상태에서 바로 적용되도록 함
//     registration.onupdatefound = () => {
//       const installingWorker = registration.installing;
//       installingWorker.onstatechange = () => {
//         if (installingWorker.state === 'installed') {
//           if (navigator.serviceWorker.controller) {
//             // 새 서비스 워커가 설치됨, 페이지를 새로고침하거나 알림으로 안내할 수 있음
//             console.log('새 서비스 워커가 설치되었습니다.');
//             window.location.reload(); // 페이지를 새로고침하여 새 서비스 워커 적용
//           } else {
//             console.log('서비스 워커가 오프라인 콘텐츠를 위해 캐시되었습니다.');
//           }
//         }
//       };
//     };

//     // // 캐시 관리
//     // const CACHE_NAME = 'my-cache-v1';
//     // const cacheWhiteList = [CACHE_NAME];

//     // caches.keys().then(cacheNames => {
//     //   cacheNames.forEach(cacheName => {
//     //     if (cacheWhiteList.indexOf(cacheName) === -1) {
//     //       caches.delete(cacheName); // 이전 캐시 삭제
//     //     }
//     //   });
//     // });
//   })
//   .catch((error) => {
//     console.error('Service Worker 등록 실패:', error);
//   });

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/firebase-messaging-sw.js')
//       .then((registration) => {
//         console.log('Service Worker registered with scope:', registration.scope);

//         registration.onupdatefound = () => {
//           const installingWorker = registration.installing;
//           installingWorker.onstatechange = () => {
//             if (installingWorker.state === 'installed') {
//               if (navigator.serviceWorker.controller) {
//                 console.log('New content is available; please refresh.');
//                 window.location.reload(); // 새로운 서비스 워커가 설치되면 페이지를 새로고침
//               } else {
//                 console.log('Content is cached for offline use.');
//               }
//             }
//           };
//         };
//       }).catch((error) => {
//         console.error('Service Worker registration failed:', error);
//       });
//   });
// }